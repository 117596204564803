import api from "../../../services/api"


const inicialState = () => {
  return {
    language: navigator.language.includes('pt') ? 'pt' : 'en'
  }
}

export default {
  namespaced: true,
  state: inicialState(),
  mutations: {
    changeLanguage(state){
      if (state.language == 'en'){
        state.language = 'pt'
      }else{
        state.language = 'en'
      }
    }
  },
  getters: {},
  actions: {},
}