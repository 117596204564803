import api from "../../../services/api"


const inicialState = () => {
  return {
    data: {}
  }
}

export default {
  namespaced: true,
  state: inicialState(),
  mutations: {
    setData(state, payload){
      state.data = payload
    }
  },
  getters: {},
  actions: {
    fetchData(context, payload) {
      api
        .get(`/portfolio`)
        .then((response) => {
          context.commit("setData", response.data.results[0] || {});
        })
        .catch((e) => {
          console.log(e);
        });
    }
  },
}