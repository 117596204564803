import Vue from 'vue'
import Vuex from 'vuex'
import portfolio from './modules/portfolio'
import baseinfo from './modules/baseinfo'
import app from './modules/app'
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {},
  modules: {
    portfolio,
    app,
  },
  plugins: [createPersistedState({ paths: ['app',] })]
})

export default store;