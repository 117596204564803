export default {
  home: "Home",
  about: "About",
  service: "Service",
  portfolio: "Portfolio",
  blog: "Blog",
  contact_menu: "Contact",
  about_me: "About me",
  main_skills: "Main Skills",
  experience: "Experience",
  education: "Education & Certification",
  view_more: "View more",
  name: "Name",
  subject: "Subject",
  message: "Message",
  submit: "Submit",
  contact: {
    title: "Contact",
    description: "Contact me to design your next successful project and enjoy quality work"
  },
  services: {
    title: "My Awesome Services",
    description: "List of services performed with expertise and advanced technologies"
  },
  projects: {
    title: "Portfolio",
    description: "See a diversity of projects developed by me"
  },
  latest_posts: {
    title: "Latest Posts",
    description: "Follow my posts to stay updated"
  },
  read_more: "Read More",
  more_info: "For more info see my",
  curriculum: "resume",
  send_email: {
    success: "Email sended successfully",
    error: "Error on send email",
  },
  email_address: "Email Address",
  phone_number: "Phone Number",
  location: "Location",
  pageNotFound: {
    title: "Page Not Found",
    description: "The page you were looking for could not be found.",
    backHome: "Back to Homepage"
  },
  featured_skills: "Featured Skills",
  all_works: "All Works",
}