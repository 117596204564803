import axios from 'axios'
// import store from "../store";

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  },
})

// Add a request interceptor
// api.interceptors.request.use(
//   function (config) {
//     // Do something before request is sent
//     if (store.getters.isAuthenticated) {
//       config.headers.Authorization = `Bearer ${store.getters.getToken.access}`;
//     }
//     return config;
//   },
//   function (error) {
//     // Do something with request error
//     return Promise.reject(error);
//   }
// );

// // Add a response interceptor
// api.interceptors.response.use(
//   function (response) {
//     // Any status code that lie within the range of 2xx cause this function to trigger
//     return response;
//   },
//   async function (error) {
//     // Any status codes that falls outside the range of 2xx cause this function to trigger
//     const originalRequest = error.config;
//     if (
//       error.response.status === 401 &&
//       originalRequest.url.includes("auth/refresh-token/")
//     ) {
//       store.dispatch('logout');
//       return Promise.reject(error);
//     } else if (error.response.status === 401 && !originalRequest._retry) {
//       originalRequest._retry = true;
//       await store.dispatch("refreshToken");
//       return api(originalRequest);
//     }
//     return Promise.reject(error);
//   }
// );

export default api;