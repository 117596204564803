export default {
  home: "Inicio",
  about: "Sobre",
  service: "Serviços",
  portfolio: "Portfolio",
  blog: "Blog",
  contact_menu: "Contato",
  about_me: "Sobre mim",
  main_skills: "Competências",
  experience: "Experiência",
  education: "Educação e Certificados",
  view_more: "Ver mais",
  name: "Nome",
  subject: "Assunto",
  message: "Mensagem",
  submit: "Enviar",
  contact: {
    title: "Contato",
    description: "Contata-me para projetar-mos o seu próximo projeto de sucesso e desfrutares de trabalhos de qualidade"
  },
  services: {
    title: "Meus Serviços",
    description: "Lista dos serviços executados com expertice e tecnologias de ponta"
  },
  projects: {
    title: "Portfolio",
    description: "Veja uma diversidade de projetos desenvolvidos por mim"
  },
  latest_posts: {
    title: "Últimas Postgens",
    description: "Segue as minhas postagens para ficares sempre atualizado"
  },
  read_more: "Ler Mais",
  more_info: "Para mais informação ver meu",
  curriculum: "currículo",
  send_email: {
    success: "Email enviado com sucesso",
    error: "Erro ao enviar email",
  },
  email_address: "Endereço Email",
  phone_number: "Número de Telefone",
  location: "Localização",
  pageNotFound: {
    title: "Page Not Found",
    description: "A página que você estava procurando não foi encontrada.",
    backHome: "Voltar para Página Inicial"
  },
  featured_skills: "Competências em Destaque",
  all_works: "Todos os Trabalhos",
}